(function() {
    'use strict';
	angular.module('eventvods')
		.constant('GamesExtras', {
			"lol": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "Match Stats",
					"icon": "stats.svg",
				}
			},
			"dota": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "DotaBuff",
					"icon": "dotabuff.svg",
				}
			},
			"csgo": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "HLTV",
					"icon": "hltv.svg",
				}
			},
			"overwatch": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "Over.gg",
					"icon": "stats.svg",
				}
			},
			"pubg": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "Statistics",
					"icon": "stats.svg",
				}
			},
			"rocket-league": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "Octane.gg",
					"icon": "octane.svg",
				}
			},
			"valorant": {
				"highlights": {
					"text": "Highlights",
					"icon": "highlights.svg"
				},
				"stats": {
					"text": "Statistics",
					"icon": "stats.svg",
				}
			},
		})
        .controller('EventController', ['$rootScope', '$routeParams', '$http', 'API_BASE_URL', '$location', '$anchorScroll', '$timeout', '$window', 'GamesExtras', '$cookies',
            function($rootScope, $routeParams, $http, API, $location, $anchorScroll, $timeout, $window, GamesExtras, $cookies) {
                var vm = this;
                vm.data = null;
                vm.simple_tables = false;
                vm.showDetails = false;
				vm.sectionIndex = 0;
				vm.added = [];

				vm.closeModal = function(){
					$('.modal.show').removeClass('show').hide();
					$('body').removeClass('modal-open');
					$('.modal-backdrop').remove();
				};

                vm.getIdentifier = function($table, $index){
                    var counter = 0, str = "";
                    for(var i =0; i < vm.sectionIndex; i++){
                        for(var c =0; c < vm.data.contents[i].modules.length; c++){
                            counter += vm.data.contents[i].modules[c].matches2.length;
                        }
                    }
                    for(var c =0; c < $table; c++){
                        counter += vm.data.contents[vm.sectionIndex].modules[c].matches2.length;
                    }
                    counter += $index;
                    if(counter > 25){
                        str += String.fromCharCode(64 + Math.floor(counter / 26))
                        str += String.fromCharCode(65+(counter % 26));
                    }
                    else str = String.fromCharCode(65+counter);
                    return str;
                }
                vm.jumpTo = function(sectionIndex) {
					$cookies.put(vm.data.slug, sectionIndex);
                    vm.section = vm.data.contents[sectionIndex];
					vm.sectionIndex = sectionIndex;
					$location.search({'s': sectionIndex});
					$anchorScroll("top");
				}

                vm.hasExtras = function(module, game) {
                    for (var i = 0; i < module.columns.length; i++) {
                        if (game.links[i].trim() !== "") return true;
                    }
                    return false;
                }
                vm.setHover = function(match, index, isHovered) {
                    if (index == 1)
                        match.team1H = isHovered;
                    else
                        match.team2H = isHovered;
				}				
				vm.displayParamCheck = function($index, $module, $last){
					if($module.$index > 5) return false;
					if($index >= 5 && $last) return true; // 6 or more matches
					if($module.$index % 3 == 0 && $last && ($module.module.matches2.length <= 5) && ($module.$$prevSibling.module.matches2.length <= 5)) return true; // Every 3 days for days <= 5 matches
					return false;
				}
				vm.streamType = function(name){
					name = name.toLowerCase();
					if(name.match('twitch')) return "twitch";
					if(name.match('youtube')) return "youtube";
					if(name.match('facebook')) return "facebook";
					else return "website";
				}
                $http.get(API + '/events/slug/' + $routeParams.slug)
                    .then(function(res) {
						vm.data = res.data;
						vm.data.stats = GamesExtras[vm.data.game.slug].stats;
						vm.data.highlights = GamesExtras[vm.data.game.slug].highlights;
                        document.title = $rootScope.meta.title = vm.data.name + " - Eventvods";
                        $rootScope.meta.description = "Watch all " + vm.data.name + " esports vods and highlights on demand,  easily and spoiler-free with Eventvods.";
						$rootScope.game = res.data.game.slug;
						vm.sectionIndex = parseInt($routeParams.s || $cookies.get(vm.data.slug) || 0);
						vm.data.contents.forEach(function(section){
							section.modules.forEach(function(module){
								module.highlights = module.columns.indexOf('Highlights');
								module.stats = module.columns.indexOf(vm.data.stats.text);
								module.discussion = module.columns.indexOf('Discussion');
							});
						});
						vm.jumpTo(vm.sectionIndex);
						vm.wikis = vm.data.media.filter(function(media){
							return media.type == "Wiki";
						});
						vm.websites = vm.data.media.filter(function(media){
							return media.type == "Website";
						});
						vm.updateString = "Last updated at " + $window.moment(event.updatedAt).format("MMMM Do YYYY, h:mm A");
                        $timeout(function() {
							$('[data-toggle="tooltip"]').tooltip();
							$window.prerenderReady = true;
                        }, 1);
					},
				function(){
					$rootScope.meta.status = 404;
				});

            }
        ]);
}());
